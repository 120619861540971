import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
import { ChartPersonalised, ChartThinking, ChartBalanced } from './chart/chart-1';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`Goals Information / Dashboard Results`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/29b0f49ccd2502d934887edd03732d82/54de8/goals.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAC4jAAAuIwF4pT92AAAC0klEQVQoz02TTUgUYRjH91aXoA5JRBRSRFiBfUh06FJa1KXsFhlEdMoIiQgDpcTSQ6e0wqJ0JTXLJV0tMdTA/M5sLc39GNZ1d92ZndmdmZ15P2Z2Pt431lHpf3zgx/vyf36Pi0OYQ5iFKIk1lRJMCchiCWN2dZ6ACJumZtEkNiklTuh6XA4pWWZMAR29Y/Xvhl9F4PcUknSdx3gFIsO2oqruWYwHF+anpqcjkQgAQNO0HJwASDSNmVD8QlndvsPXd52vKRpVz83ghz6BBZDDGBgmpVTW9EgkwjAMx3GSJK3BSaxFZfXSjWd5h8r3nqrfXNq+YyC9/yu/8+VE3URYzurAMC1CgyJMpVLpdFqWZVVV174NKHH3TO4+cqewtKnkVt/dlrmrPjV/UMhr9p10Ty2kFYPYggTDQiaTyWCMdV03DGMDtmrfTuw503DwclvVi0mWj477l4qnta3ti1sednqDcULJl/FQQpT/zPkCwSAAQFEUh3cBaj59P1dU5t1e8qa81RtTxJGlwInR4Kamn9setPSHVmxKLJuKCmQYJhQKxWIxQRAwxjk4Yxv9P2IHrjwvqK28OFpbNd9RMfv69PCT4x2eY43d4Yyq2ZZl2eFlnuM4lmV5npckCUJICHGxCAs4e7O5Mf/etaONFcXeR2e9j29Pfqz2DQ4lllNYB6ZJbJLkZQghQkjTNF3XnW27WIjSWSOmqvc73QU1FYWN1YUN1ZUTAyNcPKXpKxBlbXtJFv1JLrjoj0aj1mps216TxDs0PBsIapT+SrLd/t/9zN9wRhb1LIfwKkwYMTnPx0P+QIhhnPfXCmMhau/uae3qcns8Y7M+YBE5a/JYYxFy9IRmThIOgbQoCoKgKIpTuGVZObh3+NuHvs9tn7o7erwBlktqOgsRt+42Mk2bEFaRRVESRVGWZUfPXGGO2ysqYAGMZZQEgA62AcNVPe3/7mHjMP4BUOa/wmDUZXcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "goals",
            "title": "goals",
            "src": "/static/29b0f49ccd2502d934887edd03732d82/c1b63/goals.png",
            "srcSet": ["/static/29b0f49ccd2502d934887edd03732d82/5a46d/goals.png 300w", "/static/29b0f49ccd2502d934887edd03732d82/0a47e/goals.png 600w", "/static/29b0f49ccd2502d934887edd03732d82/c1b63/goals.png 1200w", "/static/29b0f49ccd2502d934887edd03732d82/d61c2/goals.png 1800w", "/static/29b0f49ccd2502d934887edd03732d82/97a96/goals.png 2400w", "/static/29b0f49ccd2502d934887edd03732d82/54de8/goals.png 4961w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`As a result of the pandemic, like all schools, PSI experienced an atypical year with unique challenges and opportunities. The significant ambiguity and necessity for change required adaptability in order to maintain the highest possible quality learning as PSI responded to health and safety precautions.  That said, we believe in the importance of reporting out data to the community regarding our progress and the challenges associated with our growth as a school. The data for 2020 - 21 is indeed indicative of a turbulent year, during which,  in some cases, data was difficult to obtain. We are continuing to analyse and collect additional data to help us better understand if there are any trends or if results are an anomaly. In this report, we provide a qualitative overview of our progress, whilst still providing a full quantitative report of all the strategic plan goals’ measures, which can be found `}<a parentName="p" {...{
        "href": "https://drive.google.com/file/d/18QJGKwplt7YON3v79uGBK7Oscm1X95JL/view"
      }}>{`here`}</a>{`.`}</p>
    <p>{`PSI’s Strategic Plan Goals were established in winter, 2019 as a visionary approach to enhance the learning experience through Thinking, Personalised, and Balanced learning and teaching.  We have found that these overarching goals encompass wide ranging effective practices in education, as well as providing strong pillars to guide us during a crisis, such as the current  pandemic.  As stated above, in a turbulent year, with continual adjustments made to the conditions of learning in order to accommodate health and safety needs, and with new programme delivery methods needed for new models of learning, it was, at times, difficult to focus on the key areas of personalised, thinking and balanced. Once again, the results are being shared with the understanding that there were significant issues that impacted our progress in some areas. We look forward, with excitement, to the 2021 - 22 School Year, as we launch our re-accreditation and International Baccalaureate (IB) Evaluation process, through the New England Association of Schools and Colleges (NEASC) and IB combined Collaborative Learning Protocol (CLP).`}</p>
    <h2 {...{
      "id": "personalised",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#personalised",
        "aria-label": "personalised permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Personalised`}</h2>
    <p>{`To develop engaged and motivated learners by providing personalised learning opportunities relevant to their lives, interests and ambitions.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Objective 1:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Learners will take a proactive role in designing and evaluating their learning.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Objective`}{` `}{`2:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Learning is differentiated, paced to learning needs and tailored to meet each student where they are.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Objective`}{` `}{`3:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Learning is engaging, relevant and enhanced by technology.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`At PSI, we believe in the power of personalised learning, where students have the opportunity to design or co-design their experience, as defined by the personalised learning continuum.  At this point, 47.4% of curricular units show evidence that students have opportunities to be co-designers of their learning.  We are striving to improve further in this area however, since only 4.1% of units currently allow learners opportunities to be designers of their own learning.`}</p>
    <p>{`We are making some progress with the personalisation of learning. For example, this year, 72.4% of secondary students agreed that what they learn in class is relevant to them, as compared to 66.9% last year. We have not, however, been advancing this goal as quickly as we would like, in part due to the impact of the pandemic.`}</p>
    <p>{`We have already begun interviewing students, and discussing with teachers, about what they desire in learning experiences for the future based on their experiences during  the pandemic.  We are committed to a full review, and update, of all our units of study in order to incorporate more opportunities for students to be designers or co-designers of their learning, to create opportunities for flexible pacing of learning, and to weave in lessons learned during the pandemic to ensure a meaningful and relevant experience aligned with students’ needs.`}</p>
    <p>{`It is worth also considering the various challenges encountered in terms of collecting data on our personalised goal. Most of it comes from either survey data or curriculum unit of study plans.  Many of our units of study underwent necessary and appropriate adjustments to reflect the blended or remote learning environment.  We also found that the community demonstrated  fatigue when it came to responding to surveys. As such our climate survey had a noticeably lower response rate compared to past years, and an unusually high proportion of  “I don’t know” responses.`}</p>
    <ChartPersonalised mdxType="ChartPersonalised" />
    <h2 {...{
      "id": "thinking",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thinking",
        "aria-label": "thinking permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thinking:`}</h2>
    <p>{`To develop students' thinking dispositions and habits by creating a culture of thinking in our classrooms and across our school.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Objective`}{` `}{`1:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Learners will transfer knowledge, skills and understanding to be able to thoughtfully, flexibly and purposefully identify problems and create solutions.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Objective`}{` `}{`2:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Learning will be inquiry based, requiring students to construct knowledge and understanding of the world.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Objective`}{` `}{`3:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Learners will explore ideas and solve problems with innovative approaches.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Fundamental to the educational experience at PSI is providing a strong culture of inquiry so that learners have the opportunity to  develop a strong disposition for thinking intentionally and effectively.  As an IB world school, this has always been a priority in our approach, as can be seen in the fact that 96.3% of curricular unit plans have inquiry embedded.  One key aspect to learning is that students are able to apply and transfer their understanding to a new context.  This year, 80% of our MYP students show evidence of this skill in more than half of their classes, which is an increase of 1.7% as compared to last year.  In this year’s annual climate survey, 87% of students indicated that they have had opportunities to generate creative ideas and solutions.  However, 10% of primary students who responded to the survey indicated “I don’t know” to this question, as such, we plan to talk with primary students to better understand if this response was due to not recognising opportunities available  in class, the wording of the question itself or simply fatigue.  Overall, we remain committed to enhancing opportunities for all learners to develop a thinking disposition and a culture of thinking in all of our classrooms.`}</p>
    <ChartThinking mdxType="ChartThinking" />
    <h2 {...{
      "id": "balanced",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#balanced",
        "aria-label": "balanced permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Balanced:`}</h2>
    <p>{`To develop cognitively, emotionally, socially, and physically balanced learners.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Objective`}{` `}{`1:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To develop socially and emotionally balanced learners.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Objective`}{` `}{`2:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Learning will enable students to be well-rounded and balanced individuals through active engagement in planned curricular and co-curricular opportunities.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Objective`}{` `}{`3:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Learning will require students to positively contribute to and responsibly participate in the digital world.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`This year has highlighted the importance of providing balanced opportunities for students to develop cognitively, emotionally, socially and physically.  As the pandemic upended routines and limited social interactions, most everyone was challenged in efforts to maintain a sense of balance. Through our secondary school’s new Advisory Programme, and increased attention to well-being in primary homeroom classes, students saw an increased focus on social and emotional learning.  This can be seen through the 93.1% of primary students who demonstrated an increased understanding of related units  explored in class.`}</p>
    <p>{`With limited intermixing of students allowed under the pandemic restrictions, our co-curricular programme needed to be adjusted with virtual ExtraCurricular Activities (ECAs) and an adjusted Cohort Sports Programme.  Under the guidance of our Athletic Director, Assistant Athletic Director, and Extra Curricular Coordinator, we were able to provide a variety of both in-person and virtual activities to meet student interests.  On the annual climate survey 78% of our secondary students expressed that they had opportunities to explore personal interests.  Given the popularity of our virtual ECAs, we plan to continue offering these activities, in addition to in-person activities, even when increased intermixing is allowed.`}</p>
    <p>{`Another area of dramatic change this year came with the increased student involvement with technology and the digital world.    When questioned about being taught how to make positive, socially responsible online contributions, 73% of primary and 82% of secondary students responded positively to the annual climate survey.  This is an increase of 6% and 10% respectively as compared to last year.`}</p>
    <p>{`Given our understanding of how important balance is to members of our community, we have added a new role of Well-being Coordinator. The colleague in this newly created position will develop a programme of additional mindfulness and recreation based activities.`}</p>
    <ChartBalanced mdxType="ChartBalanced" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      